import React, { useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import styled from "styled-components";
import ActionBar from "../components/OldActionBar";
import Button from "../components/buttons/Button";
import { navigate } from "gatsby-link";
import Link from "../components/Link";
import PageWrapper from "../components/PageWrapper";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { APPROVED, ARCHIVED, DRAFT, REVIEW } from "../constants";
import QuestionDatabaseContext from "../contexts/QuestionDatabaseContext";
import "react-tabs/style/react-tabs.css";
import {
  BRAND_LIGHT,
  DARK_GREY_1,
  DARK_GREY_4,
  LIGHT_GREY_3,
  LIGHT_GREY_4,
  REGULAR_TEXT,
  WHITE,
} from "../colors";
import DarkPageHeading from "../components/DarkPageHeading";

const GET_ALL_ROUND_TEMPLATES = gql`
  query GetAllRoundTemplates {
    roundTemplates: getAllRoundTemplates {
      id
      title
      status
    }
  }
`;

const GET_ALL_TIEBREAKER_TEMPLATES = gql`
  query GetAllTiebreakerTemplates {
    tiebreakerTemplates: getAllTiebreakerTemplates {
      id
      text
      status
    }
  }
`;

const TabsWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const LevelOneTabListWrapper = styled.div`
  margin-bottom: 40px;

  & > ul.react-tabs__tab-list {
    display: flex;
    justify-content: center;
    border-bottom: none;
  }

  & > ul.react-tabs__tab-list > li {
    outline: none;
    margin: 0 10px;
    color: ${BRAND_LIGHT};
    font-size: 22px;
    border-bottom: ${BRAND_LIGHT} solid 3px;
    border-bottom: none;
  }

  & > ul.react-tabs__tab-list > li:focus {
    box-shadow: none;
  }

  & > ul.react-tabs__tab-list > li.react-tabs__tab--selected {
    color: ${REGULAR_TEXT};
    border: none;
    border-bottom: ${DARK_GREY_1} solid 3px;
  }
`;

const LevelTwoTabListWrapper = styled.div`
  margin-bottom: 20px;

  & > ul.react-tabs__tab-list {
    padding: 0 10px;
    border-bottom: 1px solid ${LIGHT_GREY_4};
  }

  & > ul.react-tabs__tab-list > li {
    outline: none;
    color: ${WHITE};
    padding: 10px 15px;
    font-size: 18px;
    border: none;
    border-radius: 12px 12px 0 0;
    background-color: ${BRAND_LIGHT};
    margin: 0 3px;
  }

  & > ul.react-tabs__tab-list > li:focus {
    box-shadow: none;
  }

  & > ul.react-tabs__tab-list > li.react-tabs__tab--selected {
    color: ${REGULAR_TEXT};
    background-color: ${LIGHT_GREY_3};
    border-bottom: 1px solid ${LIGHT_GREY_4};
  }
`;

const RoundTemplateListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;

const RoundTemplateListingWrapper = styled.div`
  font-size: 20px;
  margin-bottom: 15px;
`;

const TiebreakerTemplateListContainer = styled(RoundTemplateListContainer)``;
const TiebreakerTemplateListingWrapper = styled(RoundTemplateListingWrapper)``;

const NoneFound = styled.div`
  color: ${DARK_GREY_4};
`;

export default function QuestionDatabase() {
  const {
    levelOne,
    levelTwoA,
    levelTwoB,
    setLevelOne,
    setLevelTwoA,
    setLevelTwoB,
  } = useContext(QuestionDatabaseContext);
  const { data: roundTemplateData } = useQuery(GET_ALL_ROUND_TEMPLATES);
  const { data: tiebreakerTemplateData } = useQuery(
    GET_ALL_TIEBREAKER_TEMPLATES
  );

  if (!roundTemplateData || !tiebreakerTemplateData) {
    return null;
  }

  const roundTemplates = roundTemplateData.roundTemplates;
  const tiebreakerTemplates = tiebreakerTemplateData.tiebreakerTemplates;

  const handleCreateRound = () => {
    navigate("/create-edit-round-template");
  };

  const handleCreateTiebreaker = () => {
    navigate("/create-edit-tiebreaker-template");
  };

  const renderRoundTemplatesWithStatus = (status) => {
    const filteredTemplates = roundTemplates.filter(
      (rt) => rt.status === status
    );

    if (filteredTemplates.length === 0) {
      return (
        <RoundTemplateListContainer>
          <NoneFound>None found.</NoneFound>
        </RoundTemplateListContainer>
      );
    }

    return (
      <RoundTemplateListContainer>
        {filteredTemplates.map((rt) => (
          <RoundTemplateListingWrapper key={rt.id}>
            <Link to={`/create-edit-round-template?id=${rt.id}`}>
              {rt.title}
            </Link>
          </RoundTemplateListingWrapper>
        ))}
      </RoundTemplateListContainer>
    );
  };

  const renderTiebreakerTemplatesWithStatus = (status) => {
    const filteredTemplates = tiebreakerTemplates.filter(
      (tt) => tt.status === status
    );

    if (filteredTemplates.length === 0) {
      return (
        <TiebreakerTemplateListContainer>
          <NoneFound>None found.</NoneFound>
        </TiebreakerTemplateListContainer>
      );
    }

    return (
      <TiebreakerTemplateListContainer>
        {filteredTemplates.map((tt) => (
          <TiebreakerTemplateListingWrapper key={tt.id}>
            <Link to={`/create-edit-tiebreaker-template?id=${tt.id}`}>
              {tt.text}
            </Link>
          </TiebreakerTemplateListingWrapper>
        ))}
      </TiebreakerTemplateListContainer>
    );
  };

  return (
    <PageWrapper>
      <DarkPageHeading>Question Database</DarkPageHeading>
      <TabsWrapper>
        <Tabs
          selectedIndex={levelOne}
          onSelect={(tabIndex) => setLevelOne(tabIndex)}
        >
          <LevelOneTabListWrapper>
            <TabList>
              <Tab>Rounds</Tab>
              <Tab>Tiebreakers</Tab>
            </TabList>
          </LevelOneTabListWrapper>

          <TabPanel>
            <Tabs
              selectedIndex={levelTwoA}
              onSelect={(tabIndex) => setLevelTwoA(tabIndex)}
            >
              <LevelTwoTabListWrapper>
                <TabList>
                  <Tab>Approved</Tab>
                  <Tab>Review</Tab>
                  <Tab>Draft</Tab>
                  <Tab>Archived</Tab>
                </TabList>
              </LevelTwoTabListWrapper>

              <TabPanel>{renderRoundTemplatesWithStatus(APPROVED)}</TabPanel>
              <TabPanel>{renderRoundTemplatesWithStatus(REVIEW)}</TabPanel>
              <TabPanel>{renderRoundTemplatesWithStatus(DRAFT)}</TabPanel>
              <TabPanel>{renderRoundTemplatesWithStatus(ARCHIVED)}</TabPanel>
            </Tabs>
          </TabPanel>
          <TabPanel>
            <Tabs
              selectedIndex={levelTwoB}
              onSelect={(tabIndex) => setLevelTwoB(tabIndex)}
            >
              <LevelTwoTabListWrapper>
                <TabList>
                  <Tab>Approved</Tab>
                  <Tab>Review</Tab>
                  <Tab>Draft</Tab>
                  <Tab>Archived</Tab>
                </TabList>
              </LevelTwoTabListWrapper>

              <TabPanel>
                {renderTiebreakerTemplatesWithStatus(APPROVED)}
              </TabPanel>
              <TabPanel>{renderTiebreakerTemplatesWithStatus(REVIEW)}</TabPanel>
              <TabPanel>{renderTiebreakerTemplatesWithStatus(DRAFT)}</TabPanel>
              <TabPanel>
                {renderTiebreakerTemplatesWithStatus(ARCHIVED)}
              </TabPanel>
            </Tabs>
          </TabPanel>
        </Tabs>
      </TabsWrapper>

      <ActionBar>
        <Button size="large" onClick={handleCreateRound}>
          Create a Round
        </Button>
        <Button size="large" onClick={handleCreateTiebreaker}>
          Create a Tiebreaker
        </Button>
      </ActionBar>
    </PageWrapper>
  );
}
